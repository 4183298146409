;(function ($) {

  'use strict';

  // function noscroll() {
  //   window.scrollTo( 0, 0 );
  // }

  var modal = {
    init: function() {

      $('body').on('click', '.modalLink', function(e) {
        e.preventDefault();

        // get id
        var id = $(this).attr('href');
        if ($(id).length) {

          // add listener to disable scroll
          // window.addEventListener('scroll', noscroll);

          $(id).addClass('modal--active');
          $('body').addClass('modal-active');

        }
      });

      // close registration
      this.close();
    },

    close: function() {

      var $modals = $('.modal');

      $('.close, .modal').on('click', function(e) {
        e.preventDefault();

        $modals.removeClass('modal--active');
        $('body').removeClass('modal-active');

        // Remove listener to disable scroll
        // window.removeEventListener('scroll', noscroll);
      });

      // stop propagation on modal wrap
      $('.modal__wrap').on('click', function(e) {
        e.stopPropagation();
      });

      // on escape
      $(document).keyup(function(e) {
        if (e.keyCode == 27) { // escape key maps to keycode `27`          

          $modals.removeClass('modal--active');
          $('body').removeClass('modal-active');

          // Remove listener to disable scroll
          // window.removeEventListener('scroll', noscroll);
        }
      });
    },
  };

  modal.init();

})(jQuery);